import React, { useEffect, useState } from "react";
import { Stack, Typography, Button, Card, Grid, useMediaQuery, useTheme, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSlides, getTandasBd, updatearrayInfoMovie } from "../../../store/home/homethunks";
import { setActiveMovieInfo } from "../../../store/home";
import { useNavigate, useSearchParams } from "react-router-dom";
import DailyCartelera from "./DailyCartelera";

function Cartelera() {
    /* Se obtiene la función para despachar acciones */
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams(); // Hook para manejar los parámetros de la URL

    /* Se obtienen las diapositivas de la cartelera desde el store */
    const { allMoviesBillboard } = useSelector((state) => state.home);

    /* Se obtienen las diapositivas de la cartelera desde el store */
    useEffect(() => {
        const fetchData = async () => {
            // Despachar la acción para obtener las diapositivas
            await dispatch(fetchSlides());
        };
        // Solo obtener las diapositivas si no están cargadas
        if (allMoviesBillboard.length === 0) {
            fetchData();
        }
    }, [dispatch, allMoviesBillboard]);

    // Se define el estado del filtro
    const [filter, setFilter] = useState("cartelera");

    useEffect(() => {
        const seccion = searchParams.get("seccion"); // Obtener el valor de "seccion"
        if (seccion === "1") setFilter("actuales");
        else if (seccion === "2") setFilter("preventa");
        else setFilter("cartelera");
    }, [searchParams]);

    // Se obtiene el tema y se verifica si el dispositivo es móvil
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil

    // Se filtran los elementos de la cartelera según el filtro seleccionado
    const filteredItems = allMoviesBillboard.filter((item) => (filter === "actuales" ? item.type === "cartelera" || item.type === "preventa" || item.type === "estreno" : item.type === "proximamente" || item.type === "preventa"));

    // Se obtiene la función para navegar entre páginas
    const navigate = useNavigate();

    // Función para manejar el clic en un elemento de la cartelera
    const handleItemClicked = (idMovie) => {
        // Obtener la fecha actual en formato 'YYYY-MM-DD'
        const currentDate = new Date().toISOString().split("T")[0];

        // Elimina y establece el ID de la película local
        localStorage.removeItem("idLocalMovie");
        localStorage.setItem("idLocalMovie", idMovie);

        // Despacha acciones para actualizar la información de la película activa
        dispatch(setActiveMovieInfo(idMovie));
        dispatch(updatearrayInfoMovie());

        // Despacha la acción para obtener tandas, pasando la fecha actual
        dispatch(getTandasBd(currentDate, 1));

        // Navega a la página de la película
        navigate("/movie");
    };
    return (
        <>
            <Stack style={{ marginTop: isMobile || isTablet ? "12%" : "3%", backgroundImage: "url(/content/img/SC/fondo-CP.svg)", backgroundSize: "cover", padding:  ("50px"), minHeight:"742px"  }}>
                <Stack spacing={-2} justifyContent="center" alignItems={isMobile ? "center" : "space-between"}>
                    <Typography className="titleCartelera"> {filter === "cartelera" ? "CARTELERA" : (filter === "actuales" ? "PELÍCULAS" : "PREVENTAS")} EN</Typography>
                    <Stack direction={isMobile || isTablet ? "column" : "row"} justifyContent="space-between" alignItems="center" spacing={isMobile ? 1 : 2}>
                        <Typography className="subtitleCartelera">STUDIO CINEMAS</Typography>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ marginBottom: isMobile || isTablet ? "4%" : "0%" }}>
                            <Button className="filterCartelera" variant="text" onClick={() => setFilter("cartelera")} style={{ color: filter === "cartelera" ? "#FFF" : "rgba(255, 255, 255, 0.50) ", borderBottom: filter === "cartelera" ? "1px solid #D9D9D9" : "none" }}>
                                CARTELERA
                            </Button>
                            <Button className="filterCartelera" variant="text" onClick={() => setFilter("actuales")} style={{ color: filter === "actuales" ? "#FFF" : "rgba(255, 255, 255, 0.50) ", borderBottom: filter === "actuales" ? "1px solid #D9D9D9" : "none" }}>
                                PELÍCULAS
                            </Button>
                            <Button className="filterCartelera" variant="text" onClick={() => setFilter("proximamente")} style={{ color: filter === "proximamente" ? "#FFF " : "rgba(255, 255, 255, 0.50) ", borderBottom: filter === "proximamente" ? "1px solid #D9D9D9" : "none" }}>
                                preventa
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Grid container spacing={2} className="carteleraContenedor" style={{ width: filter === "cartelera" ? (isMobile || isTablet ? "108%" : "auto" ) : ("auto"), background: "rgba(0, 0, 0, 0.24)", marginTop: (isMobile || isTablet ? "-11px" : "" )}}>
                    {filter === "cartelera" ? (
                        // Mostrar solo el mensaje "Cartelera"
                        <DailyCartelera />
                        
                    ) : (
                        // Mostrar los elementos filtrados según el filtro seleccionado
                        filteredItems.map((item, index) => (
                            <Grid item xs={6} sm={6} md={6} lg={3} key={index}>
                                <Card
                                    style={{
                                        height: "100%",
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                    }}
                                >
                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                                        <Stack
                                            style={{
                                                backgroundImage: `url(${item.image})`,
                                                width: isMobile ? "130px" : "271px",
                                                height: isMobile ? "200px" : isTablet ? "420px" : "420px",
                                                backgroundSize: "cover",
                                            }}
                                        >
                                            {item.type === "preventa" && (
                                                <Box
                                                    sx={{
                                                        position: "sticky",
                                                        top: "0%",
                                                        backgroundColor: "#FF03A9",
                                                        color: "#fff",
                                                        padding: "5px 10px",
                                                        fontFamily: "FuturaP-ExtraBold",
                                                        fontSize: isMobile || isTablet ? "11px" : "19px",
                                                        textTransform: "uppercase",
                                                        zIndex: 1,
                                                        width: isMobile ? "84.5%" : "93%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    preventa
                                                </Box>
                                            )}
                                            {item.type === "estreno" && (
                                                <Box
                                                    sx={{
                                                        position: isMobile || isTablet ? "sticky" : "sticky",
                                                        top: "0%",
                                                        backgroundColor: "#FFF504",
                                                        color: "#102220",
                                                        padding: "5px 10px",
                                                        fontFamily: "FuturaP-ExtraBold",
                                                        fontSize: isMobile || isTablet ? "11px" : "19px",
                                                        textTransform: "uppercase",
                                                        zIndex: 1,
                                                        width: isMobile ? "84.5%" : "93%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Estreno
                                                </Box>
                                            )}
                                        </Stack>

                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontSize: isMobile ? "8px" : "20px",
                                                textTransform: "uppercase",
                                                color: "#fff",
                                                fontFamily: "FuturaP-Bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                fontSize: isMobile ? "6px" : "15px",
                                                textTransform: "uppercase",
                                                color: "#fff",
                                                fontFamily: "FuturaP-Light",
                                                fontWeight: "100",
                                            }}
                                        >
                                            {item.subtitle}
                                        </Typography>
                                        <Button
                                            onClick={() => handleItemClicked(item.idMasterMovie)}
                                            size="small"
                                            className="buttonCartelera"
                                            style={{
                                                fontSize: isMobile ? "8px" : "15px",
                                                textTransform: "uppercase",
                                                color: "#fff",
                                                fontFamily: "FuturaP-Bold",
                                                backgroundColor: item.type === "preventa" ? "#FF03A9" : "#67BF47",
                                                borderRadius: "6px",
                                                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                                                height: isMobile ? "25px" : "40px",
                                            }}
                                        >
                                            {item.buttonText}
                                        </Button>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Stack>
        </>
    );
}

export default Cartelera;

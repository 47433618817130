import { apidescargaInvoice, delete_Temporal_InvoiceAsync, delete_Temporal_transactionAsync, envioCorreoError } from "./Api_pymentStoree"; // Importa las funciones para eliminar la factura y transacción temporal desde la API de pago.
import { FeeServiceAmount, errorMessages, secretKey } from "../../api"; // Importa valores y mensajes de error necesarios.
import { isPageError210, isPageError500, restoreStateHomeNewBd, seterrorMessage } from "../home"; // Funciones de manejo de errores en la página.
import { apiActualizarInfoCompra, deselectSeatAsync, selectSeatAsync } from "../home/Api_Movies_Provider"; // Funciones relacionadas con la selección de asientos.
import CryptoJS from "crypto-js"; // Librería para realizar operaciones criptográficas (opcional dependiendo de su uso en otro lugar del código).

/* Nuevas importaciones */
import { create_Invoice_tem_Cineverse, extraerinformaciondefactura, geTemporaryPaymentFunctionAsync, insertCHiledLine, insertFatherLine, insertPaymentAttemptAsync, sessionActiveAsync, trasnsactionPaymentAsync, updateClientTransactionAsync, updateSessionStatusAsync } from "../checkout/Api_checkout_Provider"; // Función asíncrona que maneja el pago temporal en el checkout.
import { restaurarDatosProcesodePago } from "../auth/authSlice";
import { restoreStateCheckoutNewBd } from "../checkout";

const createInvoice = async (crearTemporal, aplicarTemporal, tokenSessions, name_user, indetification_user, commonParams) => {
    return await create_Invoice_tem_Cineverse({
        ...commonParams,
        idSesion: tokenSessions,
        company: 0,
        branch: 0,
        cant: 0, // DEBEN IR VACIOS
        nombreCliente: name_user, // OBLIGATORIO
        cedulaCliente: indetification_user || "000", //OBLIGATORIO
        crear_temporal: crearTemporal, // YA NO APLICA  DEBE IR EN CERO
        aplicar_temporal: aplicarTemporal, // PARA QUE SE GENERE FACTURA EN FIRME
    });
};

/**
 * Función para limpiar los datos temporales relacionados con la sesión de pago.
 * @param {string} tokenSessions - Identificador único de la sesión.
 * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando los datos temporales se eliminan.
 */
const clearTemporaryData = async (tokenSessions) => {
    // Llama a la API para eliminar la factura temporal asociada a la sesión.
    await delete_Temporal_InvoiceAsync({ idSesion: tokenSessions });

    // Llama a la API para eliminar la transacción temporal asociada a la sesión.
    await delete_Temporal_transactionAsync({ idSesion: tokenSessions });
};

export const errorMessage = async (errorDetail) => {
    let errorInfo;

    if (errorDetail instanceof Error) {
        const message = typeof errorDetail.message === "object" ? JSON.stringify(errorDetail.message) : errorDetail.message || "Unknown error";
        errorInfo = {
            message,
            stack: errorDetail.stack || "No stack trace available",
            timestamp: new Date().toISOString(),
            url: window.location.href,
        };
    } else {
        const message = typeof errorDetail.message === "object" ? JSON.stringify(errorDetail.message) : errorDetail.message || "Unknown error";
        errorInfo = {
            ...errorDetail,
            message,
            timestamp: errorDetail.timestamp || new Date().toISOString(),
            url: errorDetail.url || window.location.href,
        };
    }

    // Construir un cuerpo HTML para el correo
    const emailBody = `
        <h2>Error Report</h2>
        <p><strong>Message:</strong> ${errorInfo.message}</p>
        <p><strong>Stack:</strong> <pre>${errorInfo.stack}</pre></p>
        <p><strong>Timestamp:</strong> ${errorInfo.timestamp}</p>
        <p><strong>URL:</strong> <a href="${errorInfo.url}" target="_blank">${errorInfo.url}</a></p>
        <p>${JSON.stringify(errorDetail, null, 2)}</p>
        <p>${errorDetail}</p>
    `;

    // Enviar el correo usando el cuerpo HTML
    await envioCorreoError({ infoData: emailBody });
};









/**
 * Función para generar el pago temporal, la cual utiliza la función asíncrona geTemporaryPaymentFunctionAsync.
 * @param {Object} movieValue - Objeto con los valores de la película que se está procesando.
 * @returns {Function} - Retorna una función que ejecuta la lógica del pago temporal.
 */
export const geTemporaryPayment = (movieValue) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la función que genera el pago temporal y espera su respuesta.
            const result = await geTemporaryPaymentFunctionAsync({ movieValue });

            // Si es necesario, despacha el resultado (en este caso, simplemente lo retorna).
            return result;
        } catch (error) {
             await errorMessage(error);
            return []; // Retorna un array vacío en caso de error para mantener la consistencia.
        }
    };
};

const handleTransaction = async (commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad) => {
    return await trasnsactionPaymentAsync({
        commonParams,
        cat_id_pricelist,
        list_Id_pricelist,
        createTemp,
        applyTemp,
        peopleCantidad,
    });
};

const processArticles = async (articles, tokenSessions, cantEntradas) => {
    // const numero = cantEntradas === 1 ? 3 : cantEntradas + 1; // Número base para calcular el número de línea
    // Inicializa el contador fuera del bucle
    let numero = 3;

    // Procesa los datos principales de los artículos
    const primaryProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                const numeroLinea = numero; // Usar el valor actual de numero
                // console.log("numeroLinea", numeroLinea);
                numero += 1;

                const commonData = {
                    idSesion: tokenSessions, // Identificador de sesión
                    product_id: sp.SP_idproduct, // ID del producto
                    product_name: sp.SP_nameProduct, // Nombre del producto
                    product_price1: sp.SP_subTotalProduct, // Precio del producto
                    cantidad: sp.SP_cantidad, // Cantidad de productos
                    product_combo: sp.SP_isCombo, // Indica si es un combo
                    presentation_type: sp.SP_presentacion, // Tipo de presentación
                    line: numeroLinea, // Número de línea calculado
                };

                // Inserta la línea del padre
                await insertFatherLine(commonData);

                // Procesa las escogencias del producto padre
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras; // Selecciona el grupo correspondiente
                        await insertCHiledLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            line: numeroLinea, // Número de línea del padre
                            id_padre: sp.SP_idproduct, // ID del producto padre
                            name_combo: sp.SP_nameProduct, // Nombre del combo
                            product_detail: escogencia.id, // Detalle del producto escogido
                            nombre_escogencia: escogencia.name, // Nombre de la escogencia
                            grupo, // Grupo seleccionado
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento primario se completen
    await Promise.all(primaryProcessingPromises.flat());

    // Procesa los artículos que no son parte de un combo (SP_padre === 0)
    const soloIndividuales = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            const numeroLinea = sp.SP_number_line + 100 + indexOff; // Calcula el número de línea basado en el índice del artículo más un offset
            const commonData = {
                idSesion: tokenSessions, // Identificador de sesión
                product_id: sp.SP_idproduct, // ID del producto
                product_name: sp.SP_nameProduct, // Nombre del producto
                product_price1: sp.SP_subTotalProduct, // Precio del producto
                cantidad: sp.SP_cantidad, // Cantidad de productos
                product_combo: sp.SP_isCombo, // Indica si es un combo
                presentation_type: sp.SP_presentacion, // Tipo de presentación
                line: numeroLinea, // Número de línea calculado
            };

            if (sp.SP_padre === 0) {
                // Verifica si el producto no es un padre
                await insertFatherLine(commonData); // Inserta la línea del producto individual
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales se completen
    await Promise.all(soloIndividuales.flat());

    // Procesa los elementos individuales si son parte de un combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                // Verifica si el producto es un padre (parte de un combo)
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 200 + indexOff; // Calcula el número de línea para el producto individual
                        await insertFatherLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            product_id: individual.id, // ID del producto individual
                            product_name: individual.name, // Nombre del producto individual
                            product_price1: individual.price, // Precio del producto individual
                            cantidad: 1, // Cantidad del producto individual (siempre 1)
                            product_combo: 0, // No es un combo
                            presentation_type: 0, // Tipo de presentación (por defecto 0)
                            line: sumaLine, // Número de línea calculado para el producto individual
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales en combos se completen
    await Promise.all(individualProcessingPromises.flat());
};

const processArticlesArticulos = async (articles, tokenSessions, cant) => {
    // const numero = cantEntradas === 1 ? 3 : cantEntradas + 1; // Número base para calcular el número de línea
    // Inicializa el contador fuera del bucle
    let numero = 1;

    // Procesa los datos principales de los artículos
    const primaryProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                const numeroLinea = numero; // Usar el valor actual de numero
                // console.log("numeroLinea", numeroLinea);
                numero += 1;

                const commonData = {
                    idSesion: tokenSessions, // Identificador de sesión
                    product_id: sp.SP_idproduct, // ID del producto
                    product_name: sp.SP_nameProduct, // Nombre del producto
                    product_price1: sp.SP_subTotalProduct, // Precio del producto
                    cantidad: sp.SP_cantidad, // Cantidad de productos
                    product_combo: sp.SP_isCombo, // Indica si es un combo
                    presentation_type: sp.SP_presentacion, // Tipo de presentación
                    line: numeroLinea, // Número de línea calculado
                };

                // Inserta la línea del padre
                const re = await insertFatherLine(commonData);

                // Procesa las escogencias del producto padre
                await Promise.all(
                    sp.SP_escogencias.map(async (escogencia) => {
                        const grupo = escogencia.group ?? escogencia.groupDrinks ?? escogencia.groupExtras; // Selecciona el grupo correspondiente
                        await insertCHiledLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            line: numeroLinea, // Número de línea del padre
                            id_padre: sp.SP_idproduct, // ID del producto padre
                            name_combo: sp.SP_nameProduct, // Nombre del combo
                            product_detail: escogencia.id, // Detalle del producto escogido
                            nombre_escogencia: escogencia.name, // Nombre de la escogencia
                            grupo, // Grupo seleccionado
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento primario se completen
    await Promise.all(primaryProcessingPromises.flat());

    // Procesa los artículos que no son parte de un combo (SP_padre === 0)
    const soloIndividuales = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            const numeroLinea = sp.SP_number_line + 100 + indexOff; // Calcula el número de línea basado en el índice del artículo más un offset
            const commonData = {
                idSesion: tokenSessions, // Identificador de sesión
                product_id: sp.SP_idproduct, // ID del producto
                product_name: sp.SP_nameProduct, // Nombre del producto
                product_price1: sp.SP_subTotalProduct, // Precio del producto
                cantidad: sp.SP_cantidad, // Cantidad de productos
                product_combo: sp.SP_isCombo, // Indica si es un combo
                presentation_type: sp.SP_presentacion, // Tipo de presentación
                line: numeroLinea, // Número de línea calculado
            };

            if (sp.SP_padre === 0) {
                // Verifica si el producto no es un padre
                await insertFatherLine(commonData); // Inserta la línea del producto individual
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales se completen
    await Promise.all(soloIndividuales.flat());

    // Procesa los elementos individuales si son parte de un combo
    const individualProcessingPromises = articles.map((article, indexOff) =>
        article.sp_bp_ecext.map(async (sp) => {
            if (sp.SP_padre === 1) {
                // Verifica si el producto es un padre (parte de un combo)
                await Promise.all(
                    sp.SP_individaules.map(async (individual) => {
                        const sumaLine = sp.SP_number_line + 200 + indexOff; // Calcula el número de línea para el producto individual
                        await insertFatherLine({
                            idSesion: tokenSessions, // Identificador de sesión
                            product_id: individual.id, // ID del producto individual
                            product_name: individual.name, // Nombre del producto individual
                            product_price1: individual.price, // Precio del producto individual
                            cantidad: 1, // Cantidad del producto individual (siempre 1)
                            product_combo: 0, // No es un combo
                            presentation_type: 0, // Tipo de presentación (por defecto 0)
                            line: sumaLine, // Número de línea calculado para el producto individual
                        });
                    }),
                );
            }
        }),
    );

    // Espera a que todas las promesas de procesamiento de productos individuales en combos se completen
    await Promise.all(individualProcessingPromises.flat());
};


/**
 * Función para generar el pago temporal, la cual utiliza la función asíncrona geTemporaryPaymentFunctionAsync.
 * @param {Object} movieValue - Objeto con los valores de la película que se está procesando.
 * @returns {Function} - Retorna una función que ejecuta la lógica del pago temporal.
 */
export const validarFacturaenfirme = (movieValue) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la función que genera el pago temporal y espera su respuesta.
            const result = await extraerinformaciondefactura({ movieValue });

            // Si es necesario, despacha el resultado o simplemente lo retorna.
            return result;
        } catch (error) {
            await errorMessage(error);
            return []; // Retorna un array vacío en caso de error para mantener la consistencia.
        }
    };
};


const crearLineaFeet = async (cantidadAsientos, tokenSessions, precioFeet) => {
    /// const precio = cantidadAsientos * 221.24;
    const precio = 250.00;

    const commonData = {
        idSesion: tokenSessions,
        product_id: 250,
        product_name: "Comision Venta Linea Butacas",
        product_price1: precio,
        cantidad: cantidadAsientos,
        product_combo: 0,
        presentation_type: 0,
        line: 601,
    };

    // Inserta la línea del padre
    await insertFatherLine(commonData);

    return "ok";
};



/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoice = (movieParam, orderid) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoice"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;

            const tokenTemp = dataUser.temporary_payment_Token;

            /* validamos si la factura ya exitente */
            const returnFactura = await dispatch(validarFacturaenfirme(tokenTemp));
            // Verifica si el array tiene al menos un elemento
            if (returnFactura.data.data.length > 0 && returnFactura.data.data[0].idinvoice_transaction_log_cv) {
                const numerodeInvoice = parseInt(returnFactura.data.data[0].idinvoice_transaction_log_cv, 10);
                if (!isNaN(numerodeInvoice) && numerodeInvoice > 0) {
                    window.location.href = "/documents-invoice?rev=" + tokenTemp;
                    return;
                }
            }

            // Obtiene los asientos seleccionados y sus IDs
            const seating = dataUser.temporary_payment_data_checkoutState.asientosSeleccionados.seating || [];
            const seatingIds = seating.map((seat) => seat.idButaca);

            // Realiza la llamada asíncrona para obtener las butacas disponibles en la sesión activa
            const resulButaca = await sessionActiveAsync({
                esquema_id: dataUser.temporary_payment_data_checkoutState.peliculaActiva.scheme_id_shifts_bp,
                esquema_detalle_id: dataUser.temporary_payment_data_checkoutState.peliculaActiva.scheme_Detail_Id_shifts_bp,
                sessionId: tokenTemp,
                fecha_pelicula: dataUser.temporary_payment_data_checkoutState.peliculaActiva.date_time_shifts_bp,
            });

            // Obtiene los IDs de las butacas de la respuesta de la sesión activa
            const resultIds = resulButaca.data.data.map((item) => item.Butaca_id);

            // Identifica las butacas que faltan o que están de más
            const missingSeats = seatingIds.filter((id) => !resultIds.includes(id)); // Butacas seleccionadas que no están en la sesión activa
            const extraSeats = resultIds.filter((id) => !seatingIds.includes(id)); // Butacas en la sesión activa que no deberían estar seleccionadas

            // Valida las butacas y toma acciones correspondientes
            if (missingSeats.length === 0 && extraSeats.length === 0) {
                // Si las butacas coinciden, se procede a generar una factura temporal
            } else {

                for (const id of extraSeats) {
                    // await deseleccionarButaca(id, details.movieData);
                    const fechaFormat = `${dataUser.temporary_payment_data_checkoutState.peliculaActiva.date_time_shifts_bp} ${dataUser.temporary_payment_data_checkoutState.peliculaActiva.start_time_shifts_bp}`;

                    await deselectSeatAsync({
                        sala_id: dataUser.temporary_payment_data_checkoutState.peliculaActiva.room_id_shifts_bp,
                        butaca_id: id,
                        esquema_id: dataUser.temporary_payment_data_checkoutState.peliculaActiva.scheme_id_shifts_bp,
                        esquema_detalle_id: dataUser.temporary_payment_data_checkoutState.peliculaActiva.scheme_Detail_Id_shifts_bp,
                        fecha: fechaFormat,
                    });
                }
            }


            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(tokenTemp);

            /* cantidad de personas en total */
            const totalquantityPeople = Math.max(checkoutState.precioDelista.quantityPeople, 0);

            // Calcular el total del paso 2 (asientos)
            const totalPriceList = totalquantityPeople > 0 ? checkoutState.precioDelista.totalStep2 : 0;

            // Calcular el subtotal de los artículos en el paso 4 (confitería)
            const totalSubTotal = checkoutState.articuloSeleccionado.reduce((acc, producto) => acc + producto.subTotal, 0);

            const FeeService = totalquantityPeople * FeeServiceAmount;

            // Calcular el total del pago incluyendo el costo del servicio y el total de asientos
            const totalPayment = totalSubTotal + FeeService + totalPriceList;

            const extraInformacion = homeState.extraInformacion[0];

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: tokenTemp, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                movieParam: movieParam,
                orderid,
            };

            const insertPyment = await insertPaymentAttemptAsync({
                idSession: tokenTemp, // ID de la sesión
                idInvoice: 0, // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalPayment, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 1, // Tipo de transacción (1 para compra)
            });

            if (insertPyment.data.data.insertId > 0) {
                const commonParams = {
                    idSesion: tokenTemp, // ID de la sesión
                    cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                    nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                    cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
                };

                let cantEntradas = 0;

                await processArticles(checkoutState.articuloSeleccionado, tokenTemp, cantEntradas);
                await crearLineaFeet(checkoutState.asientosSeleccionados.seating.length, tokenTemp, FeeServiceAmount);

                async function processTransactions() {
                    for (const item of checkoutState.precioDelista.priceList) {
                        if (item.quantity > 0) {
                            cantEntradas += item.quantity;
                            // Verifica si la cantidad es mayor a cero
                            const cat_id_pricelist = item.cat_id_pricelist_bp;
                            const list_Id_pricelist = item.list_Id_pricelist_bp;
                            const createTemp = 1;
                            const applyTemp = 0;
                            const peopleCantidad = item.quantity;
                            await handleTransaction(commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad);
                        }
                    }
                }

                await processTransactions();

                // await handleTransaction(commonParams, 0, 0, 1, 0, checkoutState.articuloSeleccionado.length);
                return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
            } else {
                return "error"; // Retorna "error" en caso de error.
            }
        } catch (error) {
            await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoiceAgain = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));
            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                const info = movieParam;
                const dataInfo = data;
                errorMessage(new Error(`No hay data disponible insertartTempInvoiceAgain. Información de la película: ${info}. Datos recibidos: ${JSON.stringify(dataInfo)}`));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;
            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const tokenTemp = dataUser.temporary_payment_Token;

            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(tokenTemp);

            const commonParams = {
                idSesion: tokenTemp, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            let cantEntradas = 0;

            await processArticles(checkoutState.articuloSeleccionado, tokenTemp, cantEntradas);

            async function processTransactions() {
                for (const item of checkoutState.precioDelista.priceList) {
                    if (item.quantity > 0) {
                        cantEntradas += item.quantity;
                        // Verifica si la cantidad es mayor a cero
                        const cat_id_pricelist = item.cat_id_pricelist_bp;
                        const list_Id_pricelist = item.list_Id_pricelist_bp;
                        const createTemp = 1;
                        const applyTemp = 0;
                        const peopleCantidad = item.quantity;
                        await handleTransaction(commonParams, cat_id_pricelist, list_Id_pricelist, createTemp, applyTemp, peopleCantidad);
                    }
                }
            }

            await processTransactions();

            // await handleTransaction(commonParams, 0, 0, 1, 0, checkoutState.articuloSeleccionado.length);
            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
       } catch (error) {
            await errorMessage(error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartFirmeInvoice = (movieParam) => {
    return async (dispatch, getState) => {

        try {
            // Despacha la acción para obtener la información del pago temporal
            const { data } = await dispatch(geTemporaryPayment(movieParam));
            const [dataUser] = data.data;

            // Extraemos datos del usuario y estados de pago
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const informacionDelbanco = dataUser.temporary_payment_data_banck;
            const tokenTemp = dataUser.temporary_payment_Token;

            await dispatch(restaurarDatosProcesodePago(dataUser.temporary_payment_data_authUser.datosUsuario));
            await dispatch(restoreStateHomeNewBd(dataUser.temporary_payment_data_homeState));
            await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));

            // Desencriptar información del pago
            let transactionId = informacionDelbanco.transactionid || 0;

            // Datos del cliente y otros valores predeterminados
            const cedulaUser = datosUsuario.indetification_user || "000";
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`;
            const cantseating = checkoutState.asientosSeleccionados.seating.length;

            /* validamos si la factura ya exitente */
            const returnFactura = await dispatch(validarFacturaenfirme(tokenTemp));
            // Verifica si el array tiene al menos un elemento
            if (returnFactura.data.data.length > 0 && returnFactura.data.data[0].idinvoice_transaction_log_cv) {
                const numerodeInvoice = parseInt(returnFactura.data.data[0].idinvoice_transaction_log_cv, 10);
                if (!isNaN(numerodeInvoice) && numerodeInvoice > 0) {
                    window.location.href = "/documents-invoice?rev=" + tokenTemp;
                    return;
                }
            }

            // Parámetros comunes
            const commonParams = {
                idSesion: tokenTemp,
                cant: "ya no se usa",
                nombreCliente: nameUser,
                cedulaCliente: cedulaUser,
            };

            // Llamada a la transacción
            const returnInvoiceId = await handleTransaction(commonParams, 0, 0, 0, 1, 0);
            const invoiceIdBp = returnInvoiceId?.data?.data?.[0]?.Invoice_Id ?? 0;

            // Asignación de estado de la compra
            let estadoCompra = invoiceIdBp > 0 || transactionId > 0 ? "aceptado" : "pendiente";
            let bussisnes_pro = invoiceIdBp > 0 ? "aceptado" : "Pendiente bussisnes_pro";
            let banck = transactionId > 0 ? "aceptado" : "Pending del banco";

            // if (invoiceIdBp === 0 && returnInvoiceId.data.statusCode !== 200) {
            //     // Envía el error formateado como JSON directamente sin `JSON.stringify` para mantener la estructura
            //     errorMessage(new Error("Error: El procedimiento almacenado no retornó un valor válido para invoiceIdBp. : ", transactionId, "=: ", tokenTemp));
            // }

            const extraInformacion = homeState.extraInformacion[0];

            const DataPymentPneding = {
                infoCLient: datosUsuario,
                priceList: checkoutState.precioDelista,
                seating: checkoutState.asientosSeleccionados,
                articules: checkoutState.articuloSeleccionado,
                movieData: checkoutState.peliculaActiva,
                tokenUser: tokenTemp,
                payments: checkoutState.payment,
                transBank: transactionId,
                movieDetail: extraInformacion,
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro,
                banck,
                errorBanck: informacionDelbanco,
                movieParam: movieParam,
                orderid: "revisar el orderid del banco...",
            };

            // Actualización de la transacción del cliente
            await updateClientTransactionAsync({
                idSession: tokenTemp,
                idInvoice: invoiceIdBp || 0,
                clavefe: "0",
                status: estadoCompra,
                transBank: transactionId,
                DataPymentPneding,
            });

            // Actualización del estado de la sesión si es necesario
            if (transactionId > 0 || invoiceIdBp > 0) {
                await updateSessionStatusAsync({ status: 1, sessionId: tokenTemp });
            }

            // Retornar ID de factura y transacción
            return { invoiceIdBp, transactionId };
        } catch (error) {
             await errorMessage(error);
            return { invoiceIdBp: 0, transactionId: "" };
        }
    };
};

// Función para actualizar la información de compra de una sesión de película.
export const actualizarDatosCompra = (idSesionPelicula, detallesCompraJson = "", code) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la API para modificar la información de compra con los detalles proporcionados
            await apiActualizarInfoCompra({
                idSession: idSesionPelicula, // ID de la sesión de película
                details_json: detallesCompraJson, // Detalles en formato JSON de la compra pendiente
                code,
            });
            return "ok"; // Retorna "ok" si la actualización se realiza correctamente.
       } catch (error) {
             await errorMessage(error);
            // Registra el error en la consola en caso de que falle el proceso de actualización.
            // console.error("Error al actualizar la información de compra", error);
            return "no"; // Retorna "no" en caso de error.
        }
    };
};

export const insertarErrorDeFactura = (movieValue, errorBanck) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieValue));


            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertarErrorDeFactura"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const tokenTemp = dataUser.temporary_payment_Token;


            await dispatch(restaurarDatosProcesodePago(dataUser.temporary_payment_data_authUser.datosUsuario));
            await dispatch(restoreStateHomeNewBd(dataUser.temporary_payment_data_homeState));
            await dispatch(restoreStateCheckoutNewBd(dataUser.temporary_payment_data_checkoutState));

            // Array para almacenar la información desencriptada
            const infopytmi = [];
            // Variable para almacenar el ID de la transacción
            let transactionId = "";
            const invoiceIdBp = ""; // Obtenemos el ID de la factura

            // Inicializar estados
            let estadoCompra = "Pago no procesado";
            let bussisnes_pro = "Pago no procesado";
            let banck = "Pago no procesado";
            let invoiceTemp = 0;

            const extraInformacion = homeState.extraInformacion[0];

            // Creamos un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: tokenTemp, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                errorBanck: errorBanck,
                movieValue: movieValue,
                orderid: "revisar el orderid del banco",
            };

            // Actualizamos la transacción del cliente con los datos recopilados
            await updateClientTransactionAsync({
                idSession: tokenTemp, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: "pendiente", // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });
            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            return "no"; // Retorna "ok" si todo el proceso fue exitoso.
        }
    };
};

// Función para actualizar la información de compra de una sesión de película.
export const descargaInvoice = (idCompra) => {
    return async (dispatch, getState) => {
        try {
            // Llama a la API para modificar la información de compra con los detalles proporcionados
            await apidescargaInvoice({
                idCompra: idCompra, // ID de la sesión de película
            });
            return "ok"; // Retorna "ok" si la actualización se realiza correctamente.
        } catch (error) {
            // Registra el error en la consola en caso de que falle el proceso de actualización.
            //.error("Error al actualizar la información de compra", error);
            return "no"; // Retorna "no" en caso de error.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartTempInvoiceConfiteria = (movieParam, orderid) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoiceConfiteria"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const tokenTemp = dataUser.temporary_payment_Token;

            // console.log("movieParam", movieParam);
            // console.log("checkoutState", tokenTemp);

            // Llama a la función que elimina los datos temporales utilizando el token de sesión.
            await clearTemporaryData(tokenTemp);

            // Calcular el subtotal de los artículos en el paso 4 (confitería)
            const totalSubTotal = checkoutState.articuloSeleccionado.reduce((acc, producto) => acc + producto.subTotal, 0);

            // Calcular el total del pago incluyendo el costo del servicio y el total de asientos
            const totalPayment = totalSubTotal;

            const extraInformacion = homeState.extraInformacion[0] || {};

            // Crear un objeto con los datos de pago pendientes
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: tokenTemp, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                movieParam: movieParam,
                orderid,
            };

            await insertPaymentAttemptAsync({
                idSession: tokenTemp, // ID de la sesión
                idInvoice: "", // ID de la factura (vacío en este caso)
                clavefe: "", // Clave de la factura electrónica (vacío en este caso)
                details_json: DataPymentPneding, // Detalles en formato JSON del pago pendiente
                total: totalPayment, // Monto total del pago
                status: "pendiente", // Estado de la transacción (pendiente)
                transBank: "", // Información de la transacción bancaria (vacío en este caso)
                type: 2, // Tipo de transacción (1 para compra)
            });

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: tokenTemp, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            await processArticlesArticulos(checkoutState.articuloSeleccionado, tokenTemp, 0);
            await createInvoice(1, 0, tokenTemp, nameUser, cedulaUser, commonParams);

            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
            await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

/**
 * Función para insertar la factura temporal.
 * @param {Object} movieParam - Objeto con los parámetros de la película.
 * @returns {Function} - Retorna una función que inserta la factura temporal y maneja la lógica.
 */
export const insertartFirmeInvoiceConfiteria = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const homeState = dataUser.temporary_payment_data_homeState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const informacionDelbanco = dataUser.temporary_payment_data_banck;
            const tokenTemp = dataUser.temporary_payment_Token;

            // Variable para almacenar el ID de la transacción
            let transactionId = informacionDelbanco.transactionid || 0;

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: tokenTemp, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };
            const returnInvoiceId = await createInvoice(0, 1, tokenTemp, nameUser, cedulaUser, commonParams);
            const invoiceIdBp = returnInvoiceId?.data?.data?.[0]?.Invoice_Id ?? 0;

            let estadoCompra = "pendiente";
            let bussisnes_pro = "Pendiente bussisnes_pro";
            let banck = "Pending del banco";
            let invoiceTemp = 0;

            if (invoiceIdBp || transactionId > 0) {
                estadoCompra = "aceptado";
            }

            if (invoiceIdBp) {
                bussisnes_pro = "aceptado";
                invoiceTemp = invoiceIdBp;
            }

            if (transactionId > 0) {
                banck = "aceptado";
            }
            const extraInformacion = homeState.extraInformacion[0];
            const DataPymentPneding = {
                infoCLient: datosUsuario, // Información del cliente
                priceList: checkoutState.precioDelista, // Información de la lista de precios (asientos)
                seating: checkoutState.asientosSeleccionados, // Información de los asientos seleccionados
                articules: checkoutState.articuloSeleccionado, // Información de los artículos comprados en la confitería
                movieData: checkoutState.peliculaActiva, // Información de la tanda de la película
                tokenUser: tokenTemp, // Token de la sesión del usuario
                payments: checkoutState.payment, // Información del pago
                transBank: transactionId, // ID de la transacción bancaria
                movieDetail: extraInformacion, // Detalles adicionales de la película
                FeeServiceAmount: FeeServiceAmount,
                bussisnes_pro: bussisnes_pro,
                banck: banck,
                errorBanck: informacionDelbanco,
                movieParam: movieParam,
            };
            await updateClientTransactionAsync({
                idSession: tokenTemp, // ID de la sesión
                idInvoice: invoiceTemp, // ID de la factura
                clavefe: "0", // Clave de la factura electrónica (0 en este caso)
                status: estadoCompra, // Estado de la transacción (aceptado)
                transBank: transactionId, // ID de la transacción bancaria
                DataPymentPneding: DataPymentPneding, // Datos de pago pendientes
            });


            return { invoiceIdBp, transactionId };
       } catch (error) {
             await errorMessage(error);
            return { invoiceIdBp: 0, transactionId: "" };
        }
    };
};

export const insertartTempInvoiceConfiteriaAgain = (movieParam) => {
    return async (dispatch, getState) => {
        try {
            // Despacha la acción para obtener la información del pago temporal y extrae la respuesta.
            const { data } = await dispatch(geTemporaryPayment(movieParam));

            // Valida si el código de estado es diferente de 200 (éxito).
            if (data.statusCode !== 200) {
                errorMessage(new Error("No hay data disponible insertartTempInvoiceConfiteriaAgain"));
                return "error"; // Retorna "error" si el código de estado no es 200.
            }
            // Extrae los datos del usuario desde la respuesta obtenida.
            const [dataUser] = data.data;

            // Extrae el token de la sesión desde el estado del checkout.
            const checkoutState = dataUser.temporary_payment_data_checkoutState;
            const datosUsuario = dataUser.temporary_payment_data_authUser.datosUsuario;
            const tokenTemp = dataUser.temporary_payment_Token;

            await clearTemporaryData(tokenTemp);

            const cedulaUser = datosUsuario.indetification_user || "000"; // Cédula del usuario o "000" si no está disponible
            const nameUser = `${datosUsuario.name_user} ${datosUsuario.lasname_user}`; // Nombre completo del usuario

            const commonParams = {
                idSesion: tokenTemp, // ID de la sesión
                cant: checkoutState.asientosSeleccionados.seating.length, // Cantidad de asientos seleccionados
                nombreCliente: `${datosUsuario.name_user} ${datosUsuario.lasname_user}`, // Nombre completo del cliente
                cedulaCliente: datosUsuario.indetification_user || "000", // Identificación del cliente o "000" si no está disponible
            };

            await processArticlesArticulos(checkoutState.articuloSeleccionado, tokenTemp, 0);
            await createInvoice(1, 0, tokenTemp, nameUser, cedulaUser, commonParams);

            return "ok"; // Retorna "ok" si todo el proceso fue exitoso.
        } catch (error) {
             await errorMessage(error);
            // Muestra un error en la consola si ocurre algún fallo en el proceso.
            // console.error("Error inserting temporary invoice", error);
            return "no"; // Retorna "no" en caso de error para indicar el fallo.
        }
    };
};

import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import "../../styles/movie.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTandasBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../../store/home";
import { useCallback, useEffect, useRef, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { clearSeat, obtenerCarteleraDiaria, setActiveChekout } from "../../../store/checkout";

function generateToken() {
    const date = new Date();
    const components = ["session", Math.random().toString(36).slice(2, 11), "movie", date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), Math.floor(Math.random() * 1000)];
    return components.sort(() => Math.random() - 0.5).join("");
}

// Función para formatear una fecha en un formato específico (YYYY-MM-DD).
// Recibe un objeto `Date` como entrada.
const formatDate = (date) =>
    date
        // Convierte la fecha al formato de cadena local, utilizando la configuración de Costa Rica.
        .toLocaleDateString("es-CR", {
            year: "numeric", // Incluye el año con 4 dígitos.
            month: "2-digit", // Incluye el mes como un número de dos dígitos (e.g., "01" para enero).
            day: "2-digit", // Incluye el día como un número de dos dígitos (e.g., "05").
            timeZone: "America/Costa_Rica", // Asegura que se utiliza la zona horaria de Costa Rica.
        })
        .split("/") // Divide la fecha resultante en partes usando "/" como separador (formato DD/MM/YYYY).
        .reverse() // Invierte el orden de las partes para pasar de DD/MM/YYYY a YYYY-MM-DD.
        .join("-"); // Une las partes con "-" para formar el formato final (YYYY-MM-DD).

const DailyCartelera = () => {
    // Se obtiene el tema y se verifica si el dispositivo es móvil
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    // Se obtiene la función para navegar entre páginas
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickTanda = (idMasterMovie, horarios) => {
        // Generar un ítem en localStorage
        localStorage.setItem("idLocalMovie", idMasterMovie); // Cambia "12345" por el valor que desees almacenar

        const token = generateToken();
        dispatch(clearSeat());
        dispatch(setActiveMovieInfo(idMasterMovie));
        dispatch(updatearrayInfoMovie());
        dispatch(setActiveChekout(horarios));
        navigate(`/checkout?movie=${encodeURIComponent(token)}`);

        // Mueve el scroll al top de la ventana
        window.scrollTo(0, 0);
    };

    // Función para manejar el clic en un elemento de la cartelera para ir a la pagina de la pelicula
    const handleItemClicked = (idMovie) => {
        // Obtener la fecha actual en formato 'YYYY-MM-DD'
        const currentDate = new Date().toISOString().split("T")[0];

        // Elimina y establece el ID de la película local
        localStorage.removeItem("idLocalMovie");
        localStorage.setItem("idLocalMovie", idMovie);

        // Despacha acciones para actualizar la información de la película activa
        dispatch(setActiveMovieInfo(idMovie));
        dispatch(updatearrayInfoMovie());

        // Despacha la acción para obtener tandas, pasando la fecha actual
        dispatch(getTandasBd(currentDate, 1));

        // Navega a la página de la película
        navigate("/movie");

        // Mueve el scroll al top de la ventana
        window.scrollTo(0, 0);
    };

    // Estado para almacenar un arreglo de los días de la semana.
    // `weekDays` será un array de objetos con la estructura:
    // [{ label: "Hoy (Lunes) 20 Nov", value: "2024-11-20" }, ...]
    // Inicialmente se establece como un array vacío.
    const [weekDays, setWeekDays] = useState([]);

    // Estado para almacenar el día seleccionado por el usuario.
    // `selectedDay` será un objeto con la estructura:
    // { label: "Lunes: 20 Nov", value: "2024-11-20" }
    // Inicialmente se establece como `null` para indicar que no hay selección.
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        // Obtener la fecha actual en la zona horaria de Costa Rica (o la local del sistema).
        const costaRicaDate = new Date();

        // Formatear la fecha actual a un formato específico utilizando la función `formatDate`.
        const currentFormattedDate = formatDate(costaRicaDate);

        // Definir los nombres de los días de la semana y los meses abreviados.
        const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
        const monthsShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

        // Función para generar un arreglo con los próximos 7 días a partir de hoy.
        const generateWeekDays = () => {
            const days = [];

            // Iterar sobre los próximos 7 días (incluyendo el actual).
            for (let i = 0; i < 7; i++) {
                const itemDate = new Date();

                // Ajustar la fecha para cada iteración, sumando `i` días.
                itemDate.setDate(costaRicaDate.getDate() + i);

                // Formatear la fecha en el formato requerido.
                const formattedDate = formatDate(itemDate);

                // Verificar si el día actual es "Hoy".
                const isToday = formattedDate === currentFormattedDate;

                // Crear una etiqueta descriptiva para cada día.
                // Si es hoy, incluir "(Hoy)" en la etiqueta.
                const dayLabel = isToday ? `Hoy (${daysOfWeek[itemDate.getDay()]}) ${itemDate.getDate()} ${monthsShort[itemDate.getMonth()]}` : `${daysOfWeek[itemDate.getDay()]}: ${itemDate.getDate()} ${monthsShort[itemDate.getMonth()]}`;

                // Agregar el día al arreglo como un objeto con `label` y `value`.
                days.push({
                    label: dayLabel,
                    value: formattedDate,
                });
            }

            // Ordenar los días por fecha (por si acaso las fechas no están en orden cronológico).
            days.sort((a, b) => new Date(a.value) - new Date(b.value));

            return days; // Devolver el arreglo generado.
        };

        // Generar los días y actualizar el estado con el arreglo generado.
        setWeekDays(generateWeekDays());
    }, []); // Este efecto se ejecuta solo una vez, al montar el componente.

    // Maneja el cambio de día seleccionado en un elemento de entrada (como un select o un dropdown).
    const handleDayChange = (event) => {
        // Obtiene el valor seleccionado del evento disparado (e.g., el valor seleccionado en un dropdown).
        const selectedValue = event.target.value;

        // Busca en la lista `weekDays` el objeto que coincida con el valor seleccionado
        // y extrae su propiedad `label` para obtener la etiqueta descriptiva del día.
        const selectedLabel = weekDays.find((day) => day.value === selectedValue)?.label;

        // Actualiza el estado `selectedDay` con un objeto que contiene:
        // - `value`: el valor seleccionado.
        // - `label`: la etiqueta descriptiva correspondiente al día seleccionado.
        setSelectedDay({ value: selectedValue, label: selectedLabel });

        fetchCartelera(selectedValue, 1);
    };

    // Estado para almacenar las películas diarias obtenidas
    const [movieDaily, setMovieDaily] = useState([]);

    // Estado para manejar el estado de precarga (loading)
    const [preload, setPreload] = useState(false);

    // Estado para verificar si existen tandas disponibles
    const [existTnadas, setExistTnadas] = useState(true);

    // Función para obtener la cartelera diaria, utilizando useCallback para evitar recreaciones innecesarias
    const fetchCartelera = useCallback(
        async (fechaPredefinida, defecto) => {
            setPreload(false); // Indica que la carga está en progreso
            try {
                // Llama a la acción para obtener la cartelera diaria
                const result = await dispatch(obtenerCarteleraDiaria(fechaPredefinida, defecto));
                setMovieDaily(result);
                setExistTnadas(false); // Indica que no existen tandas
            } catch (error) {
                // Manejo de errores: imprime el error en la consola
                console.error("Error al obtener la cartelera:", error);
            } finally {
                // Marca la finalización de la carga, independientemente del éxito o error
                setPreload(true);
            }
        },
        [dispatch], // Dependencias: incluye dispatch para que el callback funcione correctamente
    );

    // Hook useEffect para llamar a fetchCartelera al montar el componente
    useEffect(() => {
        fetchCartelera(0, 0); // Pasa valores iniciales para la llamada
    }, []); // Dependencias: el array vacío asegura que esto se ejecute solo una vez

    // Estado para controlar si debe ejecutarse un proceso repetitivo
    const [shouldRun, setShouldRun] = useState(false);
    // Referencia para almacenar el intervalo que se ejecuta periódicamente
    const intervalRef = useRef(null);

    useEffect(() => {
        // Formatea una fecha en formato "YYYY-MM-DD" con la zona horaria de Costa Rica
        const formatDate = (date) =>
            date
                .toLocaleDateString("es-CR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "America/Costa_Rica",
                })
                .split("/")
                .reverse()
                .join("-");

        // Procesa las películas del día actual
        const processMovies = () => {
            const today = formatDate(new Date()); // Obtiene la fecha actual formateada
            let hasMatch = false; // Indica si hay una coincidencia con la fecha actual

            // Actualiza las tandas de películas cuya fecha coincide con la de hoy
            const updatedMovies = movieDaily.map((movie) => {
                if (movie.fecha === today) {
                    hasMatch = true; // Marca que existe una coincidencia
                    // Actualizar el estado de las tandas según la hora actual
                    const updatedTandas = movie.tandas.map((tanda) => {
                        // Obtener la hora actual en formato de 24 horas
                        const currentTime = new Date().toLocaleTimeString("es-CR", { hour12: false });

                        // Verificar si la tanda ya ocurrió
                        if (tanda.start_time_shifts_bp <= currentTime) {
                            // console.log("ya paso la hora")
                            // Si la tanda ya pasó o está en curso, marcar su estado como `false`
                            return { ...tanda, status: false };
                        }
                        // Si la tanda aún no ha ocurrido, devolverla sin cambios
                        return tanda;
                    });

                    return {
                        ...movie,
                        tandas: updatedTandas,
                    };
                }
                return movie; // Retorna la película sin modificaciones si no coincide
            });

            // Actualiza el estado solo si hay cambios en los datos
            if (hasMatch) {
                setMovieDaily((prev) => (JSON.stringify(prev) !== JSON.stringify(updatedMovies) ? updatedMovies : prev));
            }

            // Establece si el proceso repetitivo debe ejecutarse
            setShouldRun(hasMatch);
        };

        // Procesa las películas al cargar el componente
        processMovies();

        // Configura un intervalo que ejecuta `processMovies` periódicamente si `shouldRun` está activo
        if (shouldRun) {
            intervalRef.current = setInterval(() => {
                processMovies();
            }, 5000); // Intervalo de 5 segundos
        } else {
            // Limpia el intervalo si no debe ejecutarse
            clearInterval(intervalRef.current);
        }

        // Limpia el intervalo al desmontar el componente
        return () => clearInterval(intervalRef.current);
    }, [movieDaily]); // Ejecuta el efecto solo cuando `movieDaily` cambia

    return (
        <Stack style={{ width: "100%", height: isMobile || isTablet ? "100%" : "auto" }} spacing={2}>
            {/* Contenedor de select de dias */}
            <Stack style={{ width: "100%", height: "auto" }} spacing={2}>
                {/* Contenedor de select de días */}
                <Stack alignItems={isMobile || isTablet ? "center" :"flex-end"}>
                    <select className="selectDayFilter" style={{ paddingLeft: "10px", borderRadius: "5px", width:isMobile || isTablet ? "85%" :"282px", height:isMobile || isTablet ? "50px" :"54px" }} onChange={handleDayChange}>
                        {weekDays.map((day, index) => (
                            <option key={index} value={day.value} className="optionSelect" style={{ padding: "5px" }}>
                                {day.label}
                            </option>
                        ))}
                    </select>
                </Stack>
            </Stack>
            {movieDaily.length === 0 && !existTnadas ? (
                // Contenedor de Disclaimer cuando no hay peliculas
                <Stack direction="row" justifyContent="center" alignItems="center" style={{ border: isMobile || isTablet ? "none" : "1px solid rgba(255, 255, 255, 0.20)", borderRadius: "20px", backgroundColor: isMobile || isTablet ? "transparent" : "rgba(0, 0, 0, 0.24)", height: "314px" }}>
                    <img src="/content/img/SC/nohaypelicula.svg" style={{ width: isMobile || isTablet ? "45%" : "18%" }} />

                    <Stack direction={isMobile || isTablet ? "column" : "row"} alignItems={isMobile || isTablet ? "flex-start" : "center"} spacing={isMobile || isTablet ? 1 : 20} style={{ width: isMobile || isTablet ? "80%" : "50%" }}>
                        <Stack alignItems="flex-start">
                            <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "32px" : "48px", textAlign: "justify" }}>OOPS!</Typography>
                            <Typography style={{ fontFamily: "FuturaP-Medium", color: "#fff", fontSize: isMobile || isTablet ? "15px" : "25px", textAlign: "justify" }}>
                                TANDAS AGOTADAS <br /> POR HOY
                            </Typography>
                        </Stack>

                        <Stack justifyContent="center">
                            <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "15px" : "32px", textAlign: "left", textTransform: "uppercase" }}>¡Revisá las de mañana!</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            ) : (
                // Contenedor de peliculas
                <Stack spacing={2} style={{ overflowX: "hidden" }}>
                    {preload ? (
                        movieDaily.map((movie, index) => (
                            <Stack key={index} spacing={2}>
                                <Stack key={index} direction={isMobile || isTablet ? "column" : "row"} spacing={isMobile || isTablet ? 2 : 20} justifyContent="center" style={{ paddingLeft: "1%", paddingBottom: "1%", paddingTop: "1%" }}>
                                    {/* Contenedor de informacion de pelicula */}
                                    <Stack className="cardMovieDailiy" justifyContent={isMobile || isTablet ? "center" : "flex-start"} direction={isMobile || isTablet ? "row" : "column"} key={index} spacing={2} onClick={() => handleItemClicked(movie.id)} alignItems="center" style={{ width: isMobile || isTablet ? "100%" : "134px" }}>
                                        <Stack
                                            style={{
                                                backgroundImage: `url(${movie.image})`,
                                                width: isMobile ? "59px" : isTablet ? "114px" : "134px",
                                                height: isMobile ? "84px" : isTablet ? "161px" : "210px",
                                                minWidth: isMobile ? "59px" : "134px",
                                                maxWidth: isMobile ? "59px" : "134px",
                                                backgroundSize: "cover",
                                                backgroundPosition: "top",
                                            }}
                                        >
                                            {movie.type === "preventa" && (
                                                <Box
                                                    sx={{
                                                        position: isMobile || isTablet ? "relative" : "sticky",
                                                        top: "0%",
                                                        backgroundColor: "#FF03A9",
                                                        color: "#fff",
                                                        padding: isMobile ? "3px 5px" : "5px 10px",
                                                        fontFamily: "FuturaP-ExtraBold",
                                                        fontSize: isMobile || isTablet ? "8px" : "19px",
                                                        textTransform: "uppercase",
                                                        zIndex: 1,
                                                        width: isMobile ? "86.5%" : "85.5%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    preventa
                                                </Box>
                                            )}
                                            {movie.type === "estreno" && (
                                                <Box
                                                    sx={{
                                                        position: isMobile || isTablet ? "relative" : "sticky",
                                                        top: "0%",
                                                        backgroundColor: "#FFF504",
                                                        color: "#102220",
                                                        padding: isMobile ? "3px 5px" : "5px 10px",
                                                        fontFamily: "FuturaP-ExtraBold",
                                                        fontSize: isMobile || isTablet ? "8px" : "19px",
                                                        textTransform: "uppercase",
                                                        zIndex: 1,
                                                        width: isMobile ? "86.5%" : "85.5%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Estreno
                                                </Box>
                                            )}
                                        </Stack>

                                        <Stack spacing={0}>
                                            <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "15px" : "24px", textAlign: "center", lineHeight: "25px", maxWidth: "132px", minWidth: "132px", textTransform: "uppercase" }}>{movie.title}</Typography>

                                            <Typography style={{ fontFamily: "FuturaP-Light", color: "#fff", fontSize: isMobile || isTablet ? "12px" : "15px", textAlign: "center", textTransform: "uppercase" }}>{movie.censura}</Typography>

                                            <Typography style={{ fontFamily: "FuturaP-Light", color: "#fff", fontSize: isMobile || isTablet ? "12px" : "15px", textAlign: "center", textTransform: "uppercase" }}>{movie.duration}</Typography>
                                        </Stack>
                                    </Stack>
                                    {/* Contenedor de tandas */}
                                    <Stack spacing={3} style={{ width: isMobile || isTablet ? "100%" : "50%" }}>
                                        {movie.tandas.map((tanda, tandaIndex) => (
                                            <Stack key={tandaIndex} direction={isMobile || isTablet ? "column" : "row"} alignItems="center" justifyContent="space-around" spacing={isMobile || isTablet ? -1 : 0} style={{ width: isMobile || isTablet ? "98%" : "100%", height: isMobile || isTablet ? "40px" : "67px", border: "1px solid rgba(255, 255, 255, 0.20)", borderRadius: "20px" }}>
                                                <Stack direction="row" alignItems="center" justifyContent="space-around" style={{ width: "80%" }}>
                                                    <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "12px" : "20px", textAlign: "center", textTransform: "uppercase" }}>{tanda.room_name_shifts_bp}</Typography>

                                                    <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "12px" : "20px", textAlign: "center" }}>{tanda.voiceover_movie_bp}</Typography>

                                                    <Typography style={{ fontFamily: "FuturaP-ExtraBold", color: "#fff", fontSize: isMobile || isTablet ? "12px" : "20px", textAlign: "center" }}>{tanda.technology_name_shifts_bp}</Typography>

                                                    <Typography
                                                        className="clickTanda"
                                                        onClick={() => {
                                                            if (tanda.status) handleClickTanda(movie.id, tanda);
                                                        }}
                                                        disabled={!tanda.status}
                                                        style={{
                                                            fontFamily: "FuturaP-ExtraBold",
                                                            color: "#fff",
                                                            fontSize: isMobile || isTablet ? "12px" : "20px",
                                                            textAlign: "center",
                                                            width: isMobile || isTablet ? "70px" : "100px",
                                                            backgroundColor: tanda.status ? (movie.type === "preventa" ? "#FF03A9" : "#67BF47") : "#9A9D99",
                                                            height: isMobile || isTablet ? "18px" : "44px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: isMobile || isTablet ? "3px" : "6px",
                                                            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                                                        }}
                                                    >
                                                        {tanda.hora}
                                                    </Typography>
                                                </Stack>

                                                {/* {!isMobile && !isTablet && <Button
                                                // onClick={() => handleItemClicked(item.idMasterMovie)}
                                                size="small"
                                                className="buttonCartelera"
                                                style={{
                                                    fontSize: isMobile || isTablet? "80%" : "75%",
                                                    textTransform: "uppercase",
                                                    color: "#fff",
                                                    fontFamily: "FuturaP-Bold",
                                                    backgroundColor: movie.type === "preventa" ? "#FF03A9" : "#67BF47",
                                                    borderRadius: isMobile || isTablet? "3px" : "6px",
                                                    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                                                    height: isMobile ? "50%" : "40px",
                                                }}
                                            >
                                                COMPRÁ TUS ENTRADAS
                                            </Button>} */}
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>

                                {/* Mostrar Divider excepto para la última película */}
                                {index !== movieDaily.length - 1 && <Divider style={{ height: "1px", backgroundColor: "#FFFFFF33" }} />}
                            </Stack>
                        ))
                    ) : (
                        // Renderizar algo si preload es falso
                        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                            <LinearProgress color="success" />
                            <LinearProgress color="success" />
                            <LinearProgress color="success" />
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default DailyCartelera;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { insertartFirmeInvoice } from "../../../store/pymentStoree/pymentStoree";

export const PorcesoManual = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState("Procesando...");
    const [hasProcessed, setHasProcessed] = useState(false); // Estado para evitar ejecuciones múltiples

    useEffect(() => {
        const handleMovieParam = async () => {
            if (hasProcessed) return; // Evitar ejecución si ya se procesó

            const queryParams = new URLSearchParams(location.search);
            const movieValue = queryParams.get("movie");

            if (movieValue) {
                try {
                    const result = await dispatch(insertartFirmeInvoice(movieValue)); // Llamada única
                    setMessage(`Operación completada: ${JSON.stringify(result)}`);
                } catch {
                    setMessage("Error al procesar el parámetro.");
                }
                setHasProcessed(true); // Marcar como procesado
            } else {
                setMessage("No se encontró el parámetro 'movie' en la URL.");
            }
        };

        handleMovieParam();
    }, [location, dispatch, hasProcessed]);

    return (
        <div>
            <p>Proceso Manual</p>
            <p>{message}</p>
            <p>
                <a href="https://theaterboss.studiocinemascr.com/clientes/list" target="_blank">
                    Ir a la lista de clientes
                </a>
            </p>
        </div>
    );
};

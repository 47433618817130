
import { Navigate, Route, Routes } from "react-router-dom";
import { PageCartelera } from "../pages/PageCartelera/PageCartelera";
import { PageCheckout } from "../pages/PageCheckout/PageCheckout";
import { PageError } from "../pages/PageError/PageError";
import { PageHome } from "../pages/PageHome";
import { PageMovie } from "../pages/PageMovie";
import { PageConfiteria } from "../pages/PageConfiteria/PageConfiteria";
import { PageNosotros } from "../pages/PageNosotros";
import { PageLegales } from "../pages/PageLegales";
import { PaymenPro } from "../pages/success/PaymenPro";
import { Confirmations } from "../pages/success/Confirmations";
// import PdfTemplate from "../pages/success/PdfTemplate";
import FormConfiteria from "../components/FormConfiteria/FormConfiteria";
import { ConfirmationsConfiteria } from "../pages/success/ConfirmationsConfiteria";
// import PdfTemplateConf from "../pages/success/PdfTemplateConf";
import { DocumentsInvoice } from "../pages/PageDocumentPdf/DocumentsInvoice";
import { DocumentsInvoiceConf } from "../pages/PageDocumentPdf/DocumentsInvoiceConf";
import { PorcesoManual } from "../components/PorcesoManual/PorcesoManual";





export const RoutesApp = () => {
    return (
        <Routes>
            {/* ruta princial al home page */}
            <Route path="/" element={<PageHome />} />

            {/* ruta para mostrar la cartelera de películas */}
            <Route path="/cartelera" element={<PageCartelera />} />
            {/* ruta para mostrar la información de una película */}
            <Route path="/movie" element={<PageMovie />} />

            {/* ruta para el checkout de la compra */}
            <Route path="/checkout" element={<PageCheckout />} />

            {/* ruta para mostrar la confiteria independiente */}
            <Route path="/confiteria/*" element={<PageConfiteria />} />

            {/* ruta para la pagina de nosotros */}
            <Route path="/nosotros" element={<PageNosotros />} />

            {/* ruta para la pagina de Legales */}
            <Route path="/legales" element={<PageLegales />} />

            {/* ruta para manejar errores 404 */}
            <Route path="/404" element={<PageError />} />
            <Route path="/bac" element={<PaymenPro />} />
            <Route path="/success/*" element={<Confirmations />} />
            <Route path="/successConfiteria/*" element={<ConfirmationsConfiteria />} />

            <Route path="/documents-invoice/*" element={<DocumentsInvoice />} />

            {/* <Route path="/pdf/*" element={<PdfTemplate />} /> */}
            <Route path="/documentos-pdf/*" element={<DocumentsInvoiceConf />} />

            <Route path="/AgregarConfiteria" element={<FormConfiteria />} />

            <Route path="/PorcesoManual/*" element={<PorcesoManual />} />

            {/* aceptar cualquier otra ruta para permitir la navegación */}
            {/* <Route path="/*" element={<Navigate to="/" />} /> */}
        </Routes>
    );
};
